.App {
    font-family: "Roboto";
    height: 100%;
}

#root {
    height: 100%;
}

html, body {
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #052838;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.ReactVirtualized__Table__sortableHeaderIcon {
    height: 2.5em !important;
    width: 2.5em !important;
}

