.mapboxgl-ctrl-top-right {
    top: 48px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
    margin: 5px 5px 0 0;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group {
    border-radius: 16px;
}

.mapboxgl-ctrl > button {
    height: 40px;
    width: 40px;
}